import { useEffect, useState } from "react";
import { TaskItem } from "./components/TaskItem";
import { getListTasks } from "./hooks";

export const TaskList = () => {
    const [taskList, setTaskList] = useState<Array<IItem> | null>(null);

    const [modif, setMod] = useState(false);

    useEffect(() => {
        (async () => {
            const tasks: Array<IItem> | null = await getListTasks();
            setTaskList(tasks);
        })();
    }, [modif]);

    const handleMod = () => {
        setMod(!modif);
    };

    return (
        <div className="my-5">
            {taskList &&
                taskList.map((task, index) => (
                    <TaskItem
                        key={index}
                        handleMod={handleMod}
                        name={task.name}
                        data={task.data}
                        id={task.id}
                    />
                ))}
        </div>
    );
};

interface IItem {
    name: string;
    data: string;
    id: string;
}
