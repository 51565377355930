import { $api } from "../../utils/api/api";



export const FecthLottery = async () => {
    try {
         const resp = await $api.get('purchases/lottery/time/')
    return resp.data.time_remaining

    }catch(e) {
        return ""
    }
   
}
export const ListPurchases = async () => {
    try {
        const res = await $api.get('purchases/')
        return res.data
    }catch (e) {
        return []
    }
}