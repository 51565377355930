import { IAuth } from "../AuthRoot";
import { RecoveryView } from "../../../components/auth/Auth";
import { user } from "../../..";

export default function RecoveryPage({
  handleShow,
  handleDisabled,
  disabled,
  handleEmail,
  handlePass,
  email,
  password,
}: IAuth) {
  const handleUpload = async () => {
    handleDisabled(true);
    if (email.length === 0) {
      handleShow("Email не может быть пустым");
      return;
    }

    const form = new FormData();

    form.append("email", email);
    const flag = await user.recovery(form);

    if (flag) {
      handleShow("Проверьте почту, вам придет письмо с восстановлением");
    }

    handleDisabled(false);
  };

  return (
    <>
      <RecoveryView
        disabled={disabled}
        handleEmail={handleEmail}
        handleButton={handleUpload}
        email={email}
      />
    </>
  );
}
