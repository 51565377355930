import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { IProducts, ListProducts } from "./hooks";

interface IProps {
  handleSelectedProduct: (id: number, alert: string) => void;
  selectedProduct: number | null;
}

export const PRSelectCatigory = ({
  handleSelectedProduct,
  selectedProduct,
}: IProps) => {
  const [products, setProducts] = useState<Array<IProducts>>([]);
  const [category, setCategory] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      if (products && products.length > 0) return;

      setProducts(await ListProducts());
    })();
  }, [products]);

  const groupedProducts = products ? groupByCategory(products) : [];

  return (
    <>
      <FormControl fullWidth={true} color="info" sx={{ background: "white" }}>
        <InputLabel variant="filled">Категории</InputLabel>
        <Select
          onChange={(e: any) => setCategory(e.target.value)}
          value={category}
        >
          {Object.entries(groupedProducts).map(
            ([category, categoryProducts]) => (
              <MenuItem key={category} value={category}>
                {category}
              </MenuItem>
            )
          )}
        </Select>
      </FormControl>

      <div className="my-5"></div>

      <FormControl
        fullWidth
        color="info"
        sx={{ background: "white" }}
        disabled={category === null}
      >
        <InputLabel variant="filled">Название товара</InputLabel>
        <Select>
          {Object.entries(groupedProducts).map(([cat, categoryProducts]) =>
            categoryProducts.map(
              (product) =>
                category === product.category && (
                  <MenuItem
                    key={product.id}
                    value={product.id}
                    onClick={() =>
                      handleSelectedProduct(product.id, product.description)
                    }
                  >
                    {product.name}
                  </MenuItem>
                )
            )
          )}
        </Select>
      </FormControl>
    </>
  );
};

const groupByCategory = (
  products: IProducts[]
): { [key: string]: IProducts[] } => {
  return products.reduce((acc, product) => {
    if (!acc[product.category]) {
      acc[product.category] = [];
    }
    acc[product.category].push(product);
    return acc;
  }, {} as { [key: string]: IProducts[] });
};
