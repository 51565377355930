import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import { Button, Chip } from "@mui/material";
import { SemilyLogo } from "../static/semilyLogo";
import LogoutIcon from "@mui/icons-material/Logout";
import { useLocation, useNavigate } from "react-router-dom";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ReplyIcon from "@mui/icons-material/Reply";
import { user } from "..";
import {
  ACCOUNTREDIRECT,
  AUTHROOT,
  FORTUNA,
  PROFILEREDIRECT,
  TASKSROOT,
} from "../utils/consts";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
interface IProps {
  isAuth: boolean;
  back?: string;
}

export const SemilyNav = observer(({ isAuth, back }: IProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isFortunaPage = useMemo(
    () =>
      location.pathname === PROFILEREDIRECT + FORTUNA ||
      location.pathname === PROFILEREDIRECT + TASKSROOT,
    [location.pathname]
  );

  const handleClick = () => {
    if (back) {
      navigate(back);
    } else if (isFortunaPage) {
      navigate(PROFILEREDIRECT);
    } else {
      user.logout();
      navigate(AUTHROOT);
    }
  };

  const handleAccount = () => {
    navigate(ACCOUNTREDIRECT);
  };

  return (
    <div>
      <AppBar
        position="static"
        style={{ background: "#00472e", color: "white" }}
      >
        <Toolbar className="flex">
          {isFortunaPage ? (
            <>
              <Chip
                color="primary"
                label={user.tickets}
                onDelete={() => {}}
                deleteIcon={<ConfirmationNumberIcon />}
              />
              <div className="flex flex-1 mx-4 my-5">
                <Button
                  size="large"
                  variant="contained"
                  color="success"
                  onClick={() => navigate(PROFILEREDIRECT + TASKSROOT)}
                >
                  Дополнительные билеты фортуны
                </Button>
              </div>

              <IconButton edge="end" color="inherit" onClick={handleClick}>
                <ReplyIcon />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton edge="start" color="inherit">
                <SemilyLogo />
              </IconButton>

              <div className="flex justify-center flex-1"></div>
              {isAuth && (
                <>
                  <IconButton color="inherit" onClick={handleClick}>
                    <LogoutIcon />
                  </IconButton>

                  <IconButton
                    edge="end"
                    color="inherit"
                    onClick={handleAccount}
                  >
                    <AccountCircleIcon />
                  </IconButton>
                </>
              )}
            </>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
});

// const AuthRoot = () => {
//   const navigate = useNavigate();
//   return (
//     <>
//       <Button color="inherit" onClick={() => navigate(AUTHROOT)}>
//         Главная
//       </Button>
//       <Button color="inherit">Товары</Button>
//       <Button color="inherit">Контакты</Button>
//     </>
//   );
// };

// const ProfileRoot = () => {
//   const navigate = useNavigate();

//   return (
//     <>
//       {ProfileURL.map((profile) => (
//         <Button
//           key={profile.value}
//           color="inherit"
//           onClick={() => navigate(profile.key)}
//         >
//           {profile.value}
//         </Button>
//       ))}
//     </>
//   );
// };

// const ProfileURL = [
//   { key: PROFILEREDIRECT + FORTUNA, value: "Колесо фортуны" },
//   { key: PROFILEREDIRECT + PURCHASE, value: " Регистрация покупки" },
//   { key: GPTREDIRECT, value: "ИИ-Косметолог" },
//   { key: CVREDIRECT, value: "Нейрокосметолог" },
// ];
