import axios from "axios";
import { makeAutoObservable } from "mobx";
import getCookie, { $api } from "../utils/api/api";

class User {
  email: string = "";
  name: string = "";
  phone: string = "";
  tickets: number = 0;
  isAuth: boolean = false;
  errorMessage: string = "";

  pID = "";
  link: string = "";

  constructor() {
    makeAutoObservable(this);
  }

  getMe() {
    (async () => {
      try {
        const resp = await $api.get("account/");
        const data = resp.data;
        this.name = data.name;
        this.email = data.email;
        this.phone = data.phone;
        this.tickets = data.tickets;

      } catch (err) { }
    })();
  }

  setPurschase(pID: string, link: string) {
    this.pID = pID;
    this.link = link;

  }

  async registration(email: string, password: string, name: string) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}auth/users/`,
        { email, password, name }
      );

      if (response.status === 201) {
        this.errorMessage =
          "Ваш аккаунт успешно зарегистрирован, проверьте почту для подверждения регистрации";
        return;
      }
    } catch (error: any) {
      if (error.response?.data?.email) {
        this.errorMessage = error.response.data.email;
        if (
          "user with this Email already exists." ===
          error.response?.data?.email[0]
        ) {
          this.errorMessage = "Такой аккаунт уже зарегистрирован"
          // await this._getJWT(email, password)
        }
      }

      if (error.response?.data?.password) {
        this.errorMessage = error.response.data.password;
        // this.setPassError(error.response?.data?.password[0])
      }

      if (error.response?.data?.status === 400) {
        // await this._getJWT(email, password)
      }
    }
  }

  async login(email: string, password: string) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}auth/jwt/create/`,
        { email, password },
        { withCredentials: true }
      );
      localStorage.setItem("smtok", response.data.access);
      let date = new Date();
      date.setTime(date.getTime() + 24 * 60 * 60 * 1000);

      document.cookie = `smtok=${response.data.refresh
        }; path=/;expires=${date.toUTCString()}`;

      this.isAuth = true;
    } catch (error: any) {
      if (error.response) this.errorMessage = error.response.data?.detail;
    }
  }

  async changeTickets(tickets: number) {
    this.tickets = this.tickets + tickets;
    try {
      await $api.put(`account/tickets/`, { tickets: tickets });
    } catch (err) { }
  }

  exit() {
    this.isAuth = false;
  }

  async checkAuth() {
    if (!(localStorage.getItem("smtok") && getCookie("smtok"))) return;

    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}auth/jwt/verify/`, {
        token: localStorage.getItem("smtok"),
      });
      this.isAuth = true;
    } catch (error) {
      if (getCookie("smtok")) {
        console.log(error);
        try {
          await axios.post(
            `${process.env.REACT_APP_BASE_URL}auth/jwt/verify/`,
            { token: getCookie("smtok") }
          );
          this.isAuth = true;
        } catch (error) {
          console.log(error);
        }
      }
    }
  }


  logout() {
    localStorage.removeItem('smtok')
    document.cookie = `smtok=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;`
    this.isAuth = false
  }


  async recovery(form: FormData) {
    try {

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}auth/users/reset_password/`, form)

      return true
    } catch (error: any) {
      if (error.response) this.errorMessage = error.response.data?.detail;

      return false
    }
  }



  async moders(form: FormData) {
    try {

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}auth/users/moders/`, form)

      return true
    } catch (error: any) {
      if (error.response) this.errorMessage = error.response.data?.detail;

      return false
    }
  }

  async resetPassword(formData: any, uid: string, token: string) {
    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}auth/users/reset_password_confirm/`,
        { ...formData, token, uid }
      );
      return true
    } catch (error) {
      return false;
    }

  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default User;
