import { useEffect, useState } from "react";

import { HistoryItem } from "../../../components/profile/historyPurchase/historyItem";
import { ListPurchases } from "../../../components/profile/hooks";

export default function HistoryPurchase() {
  const [purchases, setPurchases] = useState<Array<IHistory>>([]);

  useEffect(() => {
    (async () => {
      setPurchases(await ListPurchases());
    })();
  }, []);

  return (
    <>
      <div style={{ color: "white" }} className="w-80 mx-auto">
        <span className="my-10 flex justify-center ProfileTitle ">
          История покупок
        </span>
      </div>
      {purchases.length > 0 &&
        purchases.map((item) => (
          <HistoryItem
            id={item.id}
            created={item.created}
            status={item.status}
          />
        ))}
    </>
  );
}

export interface IHistory {
  id: number;
  created: string;
  status: string;
}
