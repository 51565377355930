import { CVDialog } from "../../../components/cv/CVDialog";
import { useNavigate } from "react-router-dom";

export const CVStart = () => {
  const navigate = useNavigate();

  return (
    <>
      <span>Анализ фотографии и подбор косметических товаров</span>

      <div style={{ color: "black" }} className="my-4"></div>
      <CVDialog />
    </>
  );
};
