import { FormControl, OutlinedInput, useMediaQuery } from "@mui/material";

interface IProps {
  email: string;
  handleEmail: (text: string) => void;
  handleName?: (text: string) => void;
  name?: string;
}

export const RegistrationFields = ({
  email,
  handleEmail,
  name,
  handleName,
}: IProps) => {
  const isMobile = useMediaQuery("(max-width:767px)");

  return (
    <>
      <FormControl
        style={{
          marginBottom: isMobile ? "20px" : "40px",
        }}
        fullWidth={true}
      >
        <OutlinedInput
          notched={false}
          placeholder="Ваше Имя"
          className="AuthInput"
          value={name}
          onChange={(e: any) => handleName && handleName(e.target.value)}
        />
      </FormControl>
      <FormControl
        style={{
          marginBottom: isMobile ? "20px" : "40px",
        }}
        fullWidth={true}
      >
        <OutlinedInput
          className="AuthInput"
          notched={false}
          placeholder="Адрес Почты"
          value={email}
          onChange={(e: any) => handleEmail(e.target.value)}
        />
      </FormControl>
    </>
  );
};
