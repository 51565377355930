import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AUTHROOT, REGISTRATIONROOT } from "../../utils/consts";
import CampaignIcon from "@mui/icons-material/Campaign";

export const AlertContainer = ({ open, handleOpen }: IProps) => {
  const navigate = useNavigate();

  const handleReg = () => {
    handleOpen(false);
    navigate(AUTHROOT + REGISTRATIONROOT);
  };

  return (
    <div>
      <Dialog open={open} onClose={() => handleOpen(false)} fullWidth>
        <DialogTitle>
          <div
            style={{ fontSize: "64px" }}
            className="flex items-center justify-center"
          >
            <CampaignIcon fontSize="inherit" />
          </div>
        </DialogTitle>
        <DialogContent className="flex justify-center">
          <div className="flex flex-col">
            <span
              className="font-bold text-center"
              style={{ fontSize: "32px" }}
            >
              Скидка 50%
            </span>
            <span style={{ color: "#666666", fontSize: "16px" }}>
              На все товары в магазине косметики
            </span>
            <div className="text-center  my-6">
              <Button
                fullWidth
                onClick={handleReg}
                color="inherit"
                sx={{ borderRadius: "30px", background: "white" }}
              >
                Забрать подарок
              </Button>
            </div>
            <span
              style={{ fontWeight: 200, fontSize: "12px" }}
              className="text-center"
            >
              Что бы забрать награду нужно зарегистрироваться
            </span>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

interface IProps {
  open: boolean;
  handleOpen: (flag: boolean) => void;
}
