import { FortunaItem } from "../../components/fortuna/fortunaWheel";

export default function WheelPage() {
  return (
    <div
      style={{
        color: "white",
        overflow: "hidden",
      }}
    >
      <div className="flex justify-center flex-col my-12">
        <span className="WheelTitle1 snake">Колесо Фортуны</span>
        <span style={{ textAlign: "center" }}>
          Используйте свои билеты и выигрывайте удивительные призы!
        </span>
      </div>
      <div className="flex justify-center">
        <FortunaItem />
      </div>
    </div>
  );
}
