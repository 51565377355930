import { Button, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AUTHROOT, PROFILEREDIRECT } from "../../../utils/consts";

export default function EmailActivatePage() {
    const { uid, token } = useParams();
    const navigate = useNavigate();
    const [isHandled, setIsHandled] = useState<boolean>(false);
    const [status, setStatus] = useState<string>("");

    useEffect(() => {
        const Fetch = async () => {
            try {
                const data = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}auth/users/activation/`,
                    { token, uid }
                );

                if (data.status === 204) {
                    setStatus(
                        "Ваш аккаунт успешно активирован. Происходит перенаправление"
                    );
                    const intervalId = setInterval(() => {
                        navigate(PROFILEREDIRECT);
                        clearInterval(intervalId);
                    }, 1000);
                } else {
                    setStatus("Извините, повторите попытку позже");
                }
            } catch (err) {
                console.log(err);
                setStatus("Извините, повторите попытку позже");
            } finally {
                setIsHandled(true);
            }
        };

        if (!isHandled) {
            Fetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isHandled]);

    return (
        <div
            style={{ fontSize: "20px", color: "white", minWidth: "400px" }}
            className="flex justify-center  mt-96"
        >
            {!isHandled ? (
                <CircularProgress />
            ) : (
                <div className="flex flex-col">
                    <div className="flex flex-nowrap">
                        <div className="text-primary fw-bold my-5 ">
                            {status}
                        </div>
                        <CircularProgress />
                    </div>
                    <Button
                        onClick={() => navigate(AUTHROOT)}
                        variant="contained"
                    >
                        Войти
                    </Button>
                </div>
            )}
        </div>
    );
}
