import { $api } from "../../utils/api/api";

export const getMessage = async (url: string | null) => {
  try {
    if (url === null) return { next: null, previous: null, results: null };

    const resp = await $api.get(url);

    return resp.data;
  } catch (err) {
    return { next: null, previous: null, results: null };
  }
};

export interface IMessage {
  id: number;
  is_user: boolean;
  text: string;
  timestamp: string;
}

export const createMessage = async (text: string) => {
  try {
    await $api.put("messages/create/", { text: text });
  } catch (err) {}
};
