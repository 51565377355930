import { Route, Routes } from "react-router-dom";
import { GPTURL } from "../../utils/path";

export default function GPTRoot() {
  const renderRoutes = () => {
    return GPTURL.map(({ path, Component }) => (
      <Route key={path} path={path} element={<Component />} />
    ));
  };

  return (
    <div style={{ color: "white" }}>
      <Routes>{renderRoutes()}</Routes>
    </div>
  );
}
