import { $api } from "../../utils/api/api";

export const sendPurchase = async (form: FormData) => {
    try {
        const res = await $api.put('purchases/create/',form)

        if (res.status === 201) {
            return res.data.id
        }
    } catch (e) {
    }
};


    
export const ListProducts = async () => {
    try {
        const res = await $api.get('products/')
        return res.data

    }catch (e) {
    }
}

export interface IProducts {
    id: number;
  name: string;
  category: string;
  description: string;
}


