import { Route, Routes } from "react-router-dom";
import { CVURL } from "../../utils/path";

export default function CVRoot() {
  const renderRoutes = () => {
    return CVURL.map(({ path, Component }) => (
      <Route key={path} path={path} element={<Component />} />
    ));
  };

  return (
    <div
      className="flex flex-col justify-center  mx-auto"
      style={{ maxWidth: "560px" }}
    >
      <div style={{ color: "white" }} className="sm:px-5">
        <span className="PurchaseTitle flex justify-center my-5">
          Нейро-косметолог
        </span>
        <Routes>{renderRoutes()}</Routes>
      </div>
    </div>
  );
}
