import {
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useState } from "react";
import { CVPROCESS } from "../../utils/consts";
import { useNavigate } from "react-router-dom";

export const CVDialog = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState<boolean>(false);

  const handleClose = () => {
    navigate(CVPROCESS);
    setShow(!show);
  };

  return (
    <div className="mt-3 flex justify-center" style={{ color: "black" }}>
      <div className="flex flex-col">
        {arr.map((obj) => (
          <>
            <Button
              disabled={obj.disbl}
              onClick={() => setShow(!show)}
              color="inherit"
              variant="contained"
              sx={{ borderRadius: "20px" }}
            >
              {obj.name}
            </Button>
            <div className="my-4"></div>
          </>
        ))}
      </div>
      <Dialog open={show} scroll="body" onClose={handleClose}>
        <DialogTitle>Инструкция</DialogTitle>
        <DialogContentText sx={{ padding: "15px" }}>
          Привет! Для того чтобы сделать качественное селфи, следуйте этой
          инструкции: <br />
          1. Очистите объектив камеры: Убедитесь, что объектив вашего телефона
          чист, чтобы избежать размытых или пятнистых снимков.
          <br />
          2. Найдите подходящее освещение: Лучше всего фотографироваться при
          естественном дневном свете. Постарайтесь избегать прямых солнечных
          лучей, которые могут создать жесткие тени. <br />
          3. Выберите фон: Фон должен быть нейтральным и не отвлекающим.
          Избегайте перегруженных или шумных фонов. <br />
          2. Рамка и композиция: Убедитесь, что ваша голова полностью помещается
          в кадр. <br />
          3. Лицо и выражение: Старайтесь выглядеть естественно. Избегайте
          искусственных улыбок или выражений. <br />
          Вот и все! Теперь у вас должно быть отличное селфи, сделанное при
          дневном свете и отображающее вашу голову в полный рост.
        </DialogContentText>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="contained"
            color="inherit"
            sx={{ borderRadius: "15px" }}
          >
            Все понятно
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const arr = [
  { name: "Селфи", disbl: false },
  { name: "Кожа рук", disbl: true },
  { name: "Тело", disbl: true },
];
