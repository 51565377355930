import {
  CVREDIRECT,
  FORTUNA,
  GPTREDIRECT,
  PURCHASE,
} from "../../../../utils/consts";
import { IconButtonComp } from "./ItemComp";

export const ProfileButtons = () => {
  return (
    <>
      <div className="flex  flex-row my-5 flex-wrap justify-center">
        {hrefs.map((item, index) => (
          <IconButtonComp
            key={index}
            image={require(`./static/${index + 1}.png`)}
            href={item}
          />
        ))}
      </div>
    </>
  );
};

const hrefs = [FORTUNA, PURCHASE, CVREDIRECT, GPTREDIRECT];
