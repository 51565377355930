import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  List,
  MenuItem,
  Select,
} from "@mui/material";
import { saveResultsUserView } from "./hooks";

interface IProps {
  openDialog: boolean;
  changeDialog: () => void;
  data: Record<string, any> | null;
  UpdateData: (key: string, value: any) => void;
}

export const CVResult = ({
  openDialog,
  changeDialog,
  data,
  UpdateData,
}: IProps) => {
  const handleClick = async () => {
    const form = new FormData();
    Object.entries(data ?? {}).map(([key, value]) =>
      form.append(key, value === "Да" ? true : value === "Нет" ? false : value)
    );

    saveResultsUserView(form);
    changeDialog();
    alert(
      "Спасибо, данные зафиксированы. Теперь “ИИ-косметолог” может подсказать вам подходящую косметику для Вас"
    );
  };

  const handleChange = (key: string, value: any) => {
    UpdateData(key, value);
  };

  return (
    <Dialog open={openDialog} onClose={() => changeDialog()} fullWidth>
      <DialogTitle>Данные фото</DialogTitle>
      <DialogContent>
        <div>
          Это бета-тестирование модели компьютерного зрения. Предсказание модели
          сделана с оговоркой, что точность такой оценки может варьироваться в
          зависимости от качества фотографии и освещения.
        </div>
        <List>
          {Object.entries(data ?? {}).map(([key, value]) => (
            <div
              key={key}
              className="my-2 flex  justify-center align-middle flex-row"
            >
              <div className="flex-1">{translations[key]}</div>
              <div className="flex-1">
                <Select
                  onChange={(e: any) => handleChange(key, e.target.value)}
                  value={value}
                  fullWidth
                >
                  {human[key].map((obj, index) => (
                    <MenuItem key={index} value={obj}>
                      {obj}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClick}>Сохранить</Button>
      </DialogActions>
    </Dialog>
  );
};

interface IHuman {
  [key: string]: string[];
}

const human: IHuman = {
  age: [
    "0-5",
    "5-10",
    "10-15",
    "15-20",
    "20-25",
    "25-30",
    "30-35",
    "35-40",
    "40-45",
    "45-50",
    "50-55",
    "55-60",
    "60-65",
    "65-70",
    "70-75",
    "75-80",
    "80-85",
    "85-90",
    "90-95",
    "95-100",
  ],
  beard: ["Да", "Нет"],
  eye: ["Черные", "Голубые", "Карие", "Зеленые", "Серые"],
  freckles: ["Да", "Нет"],
  gender: ["Женщина", "Мужчина"],
  hair: [
    "Брюнет",
    "Седой",
    "Блонд",
    "Каштановые",
    "Рыжый",
    "Русый",
    "Цвет",
    "Нет",
  ],
  skin_color: ["Белая", "Смуглая", "Черная"],
  wrinkles: ["Да", "Нет"],
  pimples: ["Да", "Нет"],
};

interface Translation {
  [key: string]: any;
}

const translations: Translation = {
  age: "Возраст",
  beard: "Растительность на лице",
  eye: "Цвет глаз",
  freckles: "Веснушки",
  gender: "Пол",
  hair: "Цвет волос",
  skin_color: "Цвет кожи",
  wrinkles: "Наличие морщин",
  pimples: "Прыщи",
};
