import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  OutlinedInput,
  useMediaQuery,
} from "@mui/material";
import { AuthInputFields } from "./compenents/InputFields";
import { Link } from "react-router-dom";
import { AUTHROOT, MODERATOR, RECOVERY, REGISTRATION } from "../../utils/consts";
import { LoginInputFields } from "./compenents/LoginInputFields";
import { RegistrationFields } from "./compenents/RegistrationFields";
import { useState } from "react";

interface IForm {
  new_password: string;
  re_new_password: string;
}

interface IPass {
  handleButton?: () => void;
  disabled: boolean;
  setFormData?: any;
  formData?: IForm;

}

interface IModer {
  handleBut: any;
  disabled: boolean;
  password: string;
  handlePass: (text: string) => void;

}


interface IRecovery extends IPass {
  email: string;
  handleEmail: (text: string) => void;
}
interface IProps extends IRecovery {
  name: string;
  valName?: string;
  password: string;
  handlePass: (text: string) => void;
  handleName?: (text: string) => void;
}

export const AuthView = ({
  valName,
  name,
  handleButton,
  disabled,
  email,
  password,
  handleEmail,
  handlePass,
  handleName,
}: IProps) => {
  const [isEnable, setIsEnable] = useState<boolean>(true);

  return (
    <>
      <span className="AuthTitle  my-20">{name}</span>
      {name === "Вход" ? (
        <LoginInputFields email={email} handleEmail={handleEmail} />
      ) : (
        <RegistrationFields
          name={valName}
          email={email}
          handleEmail={handleEmail}
          handleName={handleName}
        />
      )}
      <AuthInputFields password={password} handlePass={handlePass} />

      {name !== "Вход" && (
        <div>
          <Checkbox
            checked={isEnable}
            onChange={() => setIsEnable(!isEnable)}
          />
          <span
            style={{
              color: "white",
            }}
          >
            Соглашаюсь с условиями{" "}
            <a
              style={{ color: "blue", textDecoration: "underline" }}
              href="https://semilyfamily.ru/policy/"
              target="_blank"
              rel="noreferrer"
            >
              оферты
            </a>
          </span>
        </div>
      )}
      <Button
        disabled={!isEnable || disabled}
        color="inherit"
        variant="contained"
        onClick={() => handleButton && handleButton()}
        sx={{
          backgroundColor: "#B50000",
          borderColor: "#E8E0C1",
          borderWidth: "4px",
          borderStyle: "solid",
          borderRadius: "10px",
          color: "white",
        }}
      >
        {name === "Вход" ? "Войти" : "Регистрация"}
      </Button>

      <div className="Link">
        <Link
          to={name === "Вход" ? AUTHROOT + REGISTRATION : AUTHROOT}
          className="flex justify-center my-3"
          style={{ color: "white" }}
        >
          {name === "Вход" ? "Перейти к регистрации" : "Уже зарегистрированы?"}
        </Link>
      </div>

      {name === "Вход" && (
        <>
          <div className="Link">
            <Link
              to={AUTHROOT + RECOVERY}
              className="flex justify-center my-3"
              style={{ color: "white" }}
            >
              Восстановить пароль
            </Link>
          </div>

          <div className="Link">
            <Link
              to={AUTHROOT + MODERATOR}
              className="flex justify-center my-3"
              style={{ color: "white" }}
            >
              Вход для модераторов
            </Link>
          </div></>

      )}
    </>
  );
};

export const RecoveryView = ({
  email,
  handleEmail,
  disabled,
  handleButton,
}: IRecovery) => {
  const isMobile = useMediaQuery("(max-width:767px)");
  return (
    <>
      <span className="AuthTitle  my-20" style={{ fontSize: "30px" }}>
        Восстановление пароля
      </span>
      <FormControl
        style={{
          marginBottom: isMobile ? "20px" : "40px",
        }}
        fullWidth={true}
      >
        <OutlinedInput
          notched={false}
          placeholder="Email"
          type="email"
          onChange={(e: any) => handleEmail(e.target.value)}
          value={email}
          className="AuthInput"
        />
      </FormControl>

      <Button
        disabled={disabled}
        color="inherit"
        variant="contained"
        onClick={() => handleButton && handleButton()}
        sx={{
          backgroundColor: "#B50000",
          borderColor: "#E8E0C1",
          borderWidth: "4px",
          borderStyle: "solid",
          borderRadius: "10px",
          color: "white",
        }}
      >
        Восстановить пароль
      </Button>
    </>
  );
};

export const PassResetView = ({
  disabled,
  handleButton,
  formData,
  setFormData,
}: IPass) => {
  const isMobile = useMediaQuery("(max-width:767px)");

  return (
    <>
      <span className="AuthTitle  my-20" style={{ fontSize: "36px" }}>
        Введите новый пароль
      </span>
      <FormControl
        style={{
          marginBottom: isMobile ? "20px" : "40px",
        }}
        fullWidth={true}
      >
        <OutlinedInput
          notched={false}
          placeholder="Пароль"
          type="email"
          onChange={(event: any) =>
            setFormData({
              ...formData,
              new_password: event.target.value,
            })
          }
          value={formData?.new_password}
          className="AuthInput"
        />
        <div className="my-4"></div>
        <OutlinedInput
          notched={false}
          placeholder="Повторите новый пароль"
          type="email"
          onChange={(event: any) =>
            setFormData({
              ...formData,
              re_new_password: event.target.value,
            })
          }
          value={formData?.re_new_password}
          className="AuthInput"
        />
      </FormControl>

      <Button
        disabled={disabled}
        color="inherit"
        variant="contained"
        onClick={() => handleButton && handleButton()}
        sx={{
          backgroundColor: "#B50000",
          borderColor: "#E8E0C1",
          borderWidth: "4px",
          borderStyle: "solid",
          borderRadius: "10px",
          color: "white",
        }}
      >
        {disabled ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          "сохранить пароль"
        )}
      </Button>
    </>
  );
};


export const ModersView = ({
  password,
  handlePass,
  disabled,
  handleBut,
}: IModer) => {


  const isMobile = useMediaQuery("(max-width:767px)");
  return (
    <>
      <span className="AuthTitle  my-20" style={{ fontSize: "30px" }}>
        Вход для модератора
      </span>
      <FormControl
        style={{
          marginBottom: isMobile ? "20px" : "40px",
        }}
        fullWidth={true}
      >
        <OutlinedInput
          notched={false}
          placeholder="Пароль"
          type="password"
          onChange={(e: any) => handlePass(e.target.value)}
          value={password}
          className="AuthInput"
        />
      </FormControl>

      <Button
        disabled={disabled}
        color="inherit"
        variant="contained"
        onClick={() => handleBut && handleBut()}
        sx={{
          backgroundColor: "#B50000",
          borderColor: "#E8E0C1",
          borderWidth: "4px",
          borderStyle: "solid",
          borderRadius: "10px",
          color: "white",
        }}
      >
        Восстановить пароль
      </Button>
    </>
  );
};