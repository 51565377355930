import { $api } from "../../utils/api/api";

export const SendGift = async (gift: string) => {
  try {
    await $api.put("account/gift/", { gift: gift });
  } catch (error) {}
};


export const GetChances = async () => {
    try {
        const res = await $api.get("fortuna/")
        return res.data
    }catch (error) {
        return null

    }
}