import { useState } from "react";
import { CVUploadPhoto } from "../../../components/cv/UploadPhoto";

export const CVView = () => {
  return (
    <>
      <span>Загрузить Фотографию</span>
      <CVUploadPhoto />
    </>
  );
};
