import { Link } from "react-router-dom";

interface IProps {
  text: string;
  href: string;
}

export const CustomLink = ({ text, href }: IProps) => {
  return (
    <Link
      key={text}
      to={href}
      className="flex justify-center mt-1 Link"
      style={{ textDecoration: "none", color: "white" }}
    >
      <div>{text}</div>
    </Link>
  );
};
