import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";

interface IProps {
  handlePass: (text: string) => void;
  password: string;
}

export const AuthInputFields = ({ password, handlePass }: IProps) => {
  const isMobile = useMediaQuery("(max-width:767px)");

  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <FormControl
        fullWidth={true}
        style={{
          marginBottom: isMobile ? "10px" : "20px",
          color: "white",
        }}
      >
        <OutlinedInput
          notched={false}
          placeholder="Пароль"
          className="AuthInput"
          value={password}
          onChange={(e: any) => handlePass(e.target.value)}
          type={showPassword ? "text" : "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword((show) => !show)}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    </>
  );
};
