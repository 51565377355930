import { useEffect } from "react";
import { AuthView } from "../../../components/auth/Auth";
import { user } from "../../..";
import { useNavigate } from "react-router-dom";
import { PROFILEREDIRECT } from "../../../utils/consts";
import { IAuth } from "../AuthRoot";

export default function LoginPage({
  handleShow,
  handleDisabled,
  disabled,
  handleEmail,
  handlePass,
  email,
  password,
}: IAuth) {
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      await user.checkAuth();
      if (user.isAuth) navigate(PROFILEREDIRECT);
    })();
  }, [navigate]);

  const handleButton = async () => {

    handleDisabled(true);
    if (!(email.length && password.length)) {
      handleShow("Логин или пароль не могут быть пустыми");
      return;
    } else if (!(email.length >= 8 || password.length >= 8)) {
      handleShow("Логин или пароль должны состоять не менее чем из 8 символов");
      return;
    }

    await user.login(email, password);

    if (user.isAuth) {
      navigate("/profile");
    } else if (user.errorMessage.length) {
      handleShow(user.errorMessage);
    }

    handleDisabled(false);
  };

  return (
    <>
      <AuthView
        name="Вход"
        handleButton={handleButton}
        disabled={disabled}
        password={password}
        email={email}
        handlePass={handlePass}
        handleEmail={handleEmail}
      />
    </>
  );
}
