export default function PolicyPage() {
    return (
        <div style={{ color: "white" }} className=" md:px-28 sm:px-5  md:py-10">
            <span className="PolicyTitle my-5">Конфиденциальность</span>
            <span>
                Отказ от ответственности
                <br />
                (1) Введение – Данный раздел регулирует использование данного
                Сайта; используя данный Сайт Вы выражаете свое согласие с
                «Отказом от ответственности». Если Вы не согласны с каким-либо
                положением «Отказа от ответственности», не используйте данный
                Сайт.
                <br />
                (2) Права на интеллектуальную собственность – при отсутствии
                указания на обратное, Администрация Сайта или их лицензиары
                обладают правами на интеллектуальную собственность в отношении
                Сайта и его содержимого. Согласно положению о Лицензии ниже, все
                права на интеллектуальную собственность защищены.
                <br />
                (3) Лицензия на пользование Сайтом – Вы можете просматривать,
                загружать для целей кеширования и распечатывать содержимое
                страниц Сайта, с учетом того, что:
                <br />
                (a) Вы не можете публиковать данные материалы (включая
                публикации на других веб-сайтах), воспроизводить или загружать
                их в любые частные или общественные системы электронного поиска.
                (Администрация Сайта поддерживает добросовестное использование
                материалов Сайта в учебных/некоммерческих целях).(b) Вы не
                можете воспроизводить, копировать, размножать, продавать,
                перепродавать или каким-либо иным образом использовать данный
                Сайт в коммерческих целях без нашего письменного на то согласия.
                <br />
                (4) Ограничение ответственности – информация на данном сайте
                предоставляется бесплатно, и, используя данный Сайт, Вы
                соглашаетесь с отказом от возможных претензий в наш адрес в
                связи с содержимым Сайта.
                <br />
                Хотя Администрация Сайта прикладывает все усилия для обеспечения
                корректности информации, размещаемой на Сайте, Администрация
                Сайта не гарантирует ее точность и правильность; Администрация
                Сайта также не берет на себя ответственность за
                работоспособность и доступность Сайта и его обновление.
                <br />
                Насколько позволяет действующее законодательство, мы не
                связываем себя какими либо ручательствами и гарантиями (включая,
                но не ограничиваясь законные требования к качеству, соответствию
                назначению, соблюдению разумной осторожности и навыков)
                <br />
                Ответственность администрации Сайта ограничена и исключена до
                пределов, дозволенных действующим законодательством.
                Администрация Сайта не несет ответственности за издержки, потери
                или ущерб, прямо или косвенно возникшие при действиях,
                регулируемых данным Отказом от ответственности, или связанные с
                использованием Сайта, включая, но не ограничиваясь упущенной
                прибылью, контрактами, деловой репутацией, данными, доходом,
                выручкой или экономией.
                <br />
                Тем не менее, ничто в рамках данного Отказа от ответственности
                не ограничивает ответственность Администрации Сайта за
                мошенничество, нанесение вреда здоровью или смерть, вызванные
                преступной небрежностью Администрации Сайта, а также любую
                другую ответственность, которая не может быть ограничена в
                рамках действующего законодательства.
                <br />
                (5) Использование Форума/Комментирование – Вы не можете
                использовать Сайт любым образом, который может привести к
                повреждению Сайта, его неработоспособности или недоступности.
                <br />
                Вы не можете использовать Сайт любым образом, прямо или
                опосредованно ведущим к нарушению законодательства, причинению
                вреда или совершению противозаконных действий.
                <br />
                Вы не можете использовать данный Сайт для маркетинговых целей
                без письменного на то разрешения Администрации Сайта.
                <br />
                Вы не можете использовать данный Сайт для осуществления массовых
                и спама.
                <br />
                Вы не можете использовать данный Сайт для копирования,
                распространения и пересылки любых незаконных материалов, или
                любых других материалов, которые могут послужить основанием для
                судебного преследования согласно Английскому или другому
                применимому законодательству. Все материалы, которые Вы
                копируете, распространяете и пересылаете посредством данного
                Сайта не должны быть дискриминирующими, непристойными,
                оскорбительными, или вызывающими ненависть. Эти материалы не
                должны нарушать чьи-либо права на интеллектуальную
                собственность, права на конфиденциальность, содержать призывов к
                незаконным действиям. Материалы не должны быть
                порнографическими, унижающими человеческое достоинство,
                угрожающими или намеренно вводящими в заблуждение.
                <br />
                Администрация Сайта оставляет за собой право по своему
                усмотрению удалить любой материал, размещенный на данном Сайте,
                заморозить или удалить Ваш персональный аккаунт, ограничить Ваш
                доступ к Сайту или начать судебное преследование в отношении
                Вас.
                <br />
                В отношении всех размещаемых Вами на данном Сайте материалов вы
                даете Администрации сайта повсеместное, безотзывное,
                неэксплозивное, свободное от обязательств право на их
                использование, воспроизведение, адаптацию, публикацию, перевод и
                распространение посредством любых видов связи и вещания, а также
                право на суб-лицензию.
                <br />
                (6) Ссылки на сторонние сайты.Некоторые ссылки на этом Сайте
                ведут к ресурсам, расположенным на сторонних сайтах.
                Администрация Сайта не несет никакой ответственности за
                доступность этих ресурсов и за их контент.
                <br />
                (7) Изменение положений Отказа от ответственности -
                Администрация Сайта может пересматривать положения Отказа от
                ответственности в любое время. Пожалуйста, ознакамливайтесь с
                положениями Отказа от ответственности регулярно.(8)
                Исчерпывающий характер Отказа от ответственности - Данный Отказ
                от ответственности носит исчерпывающий характер в отношении
                использования Сайта, и имеет приоритет над любыми другими
                соглашениями в отношении использования Сайта. (9) Правовое поле
                - все возможные споры будут разрешаться по нормам Австралийского
                права.
                <br />
                (10) Наши контакты – Вы можете связаться с нами посредством
                электронной почты.
                <br />
                Политика конфиденциальности
                <br />
                Мы не собираем никакой информации о пользователях Сайта, помимо
                тех стандартных данных, что Сайт получает автоматически (Google
                Analytics). Нижеследующие положения – стандартное соглашение о
                Политике конфиденциальности. Мы уважаем право на личную
                информацию.
                <br />
                Мы прилагаем все усилия для сохранения приватности информации о
                посетителях Сайта. Нижеследующая Политика устанавливает правила,
                согласно которым мы используем информацию о пользователях Сайта.
                <br />
                (1) Какую информацию мы собираем?Мы собираем, храним и
                используем следующие виды персональной информации:
                <br />
                (a) Информация о посещении и использовании Сайта;
                <br />
                (b) Информация, которую пользователи сайта предоставляют в целях
                регистрации на сайте или подписки на наши сервисы/почтовые
                уведомления;
                <br />
                (2) Информация о посещениях СайтаМы можем собирать информацию о
                вашем компьютере и ваших посещениях сайта, такую как IP адрес,
                географическое месторасположение, тип браузера, источник
                информации, длительность посещения, количество просмотренных
                страниц. Мы можем использовать данную информацию для управления
                Сайтом, его улучшения и в маркетинговых целях.
                <br />
                Мы используем файлы cookie. Сookie - текстовый файл, отсылаемый
                сервером браузеру, и сохраняемый браузером. Текстовый файл затем
                отсылается на сервер каждый раз, когда браузер запрашивает
                веб-страницу у сервера. Это позволяет серверу идентифицировать и
                отслеживать браузер.
                <br />
                Мы можем отсылать файлы cookie, которые затем могут быть
                сохранены вашим браузером на вашем жестком диске. Мы можем
                использовать информацию из файлов cookie в целях
                администрирования Сайта, его улучшения, а также в маркетинговых
                целях. Мы также можем использовать данную информацию для
                распознования вашего компьютера при посещении вами Сайта, и для
                персонализации сайта в соответствии с вашими нуждами. Наши
                рекламодатели также могут отсылать вам файлы cookie.
                <br />
                Большинство браузеров позволяют опционально принимать или
                отказаться от приема файлов cookie. (Например, в браузере
                Internet Explorer вы можете отказаться от приема файлов cookie,
                выбрав опцию "Block all cookies" в подразделе "Privacy" меню
                "Internet Options" раздела "Tools"). Это, однако, будет
                негативно сказываться на функциональности многих веб сайтов,
                включая данный Сайт.
                <br />
                (3) Использование ваших персональных данныхПерсональные данные,
                предоставленные при работе с Сайтом, будут использованы согласно
                положениям данной Политики. В дополнение к целям, изложенным в
                других разделах данной Политики, мы можем использовать ваши
                персональные данные в целях:(a) улучшения функциональности Сайта
                через его персонализацию;(b) отсылки информации (за исключением
                маркетинговой) вам, если она. На наш взгляд, может представлять
                интерес для вас, почтой, электронной почтой и посредством других
                технологий;(c) отсылки вам маркетинговой информации, относящейся
                к нашему бизнесу [или бизнесу тщательно отобранных третьих
                компаний], которая по нашему мнению может представлять интерес
                для вас, почтой или, если вы выразили на то свое согласие,
                посредством электронной почты или схожих технологий.(Вы можете
                известить нас в любое время, что более не заинтересованы в
                получении маркетинговой информации от нас).
                <br />
                Мы не будем предоставлять ваши персональные данные третьим
                сторонам в маркетинговых целях, без вашего согласия.
                <br />
                (4) Прочие положенияВ дополнение к положениям данной Политики,
                мы можем предоставлять информацию о Вас:
                <br />
                (a) в случаях, когда нас к этому обязывает действующее
                законодательство;(b) в связи с судебными процессами или
                возможными судебными процессами;(c) в целях реализации и защиты
                наших законных прав (включая предоставление информации третьим
                сторонам в целях предотвращения незаконных действий и
                минимизации кредитного риска);(d) продавцу или покупателю (в
                т.ч. потенциальным) бизнеса или актива, которые мы продаем или
                покупаем.Кроме случаев, указанных в данной Политике, мы не будем
                предоставлять какую-либо информацию о вас третьим сторонам.
                <br />
                (5) Трансграничная передача информации
                <br />
                Информация, которую мы получаем, может быть сохранена и
                обработана, а также передана между любыми странами, где мы
                работаем для того, чтобы позволить нам использовать данную
                информацию в соответствии с данной Политикой.
                <br />
                (6) Защита ваших персональных данных
                <br />
                Мы будем предпринимать разумные действия для защиты вашей
                персональной информации от потерь, несанкционированного
                использования или изменения. Принимая во внимание общую
                небезопасность передачи данных через сеть Интернет, мы не
                гарантируем безопасность пересылаемых через Интернет данных.
                <br />
                (7) Изменения ПолитикиМы оставляем за собой право обновлять
                данную Политику в любое время. Пожалуйста, проверяйте данную
                страницу регулярно, чтобы удостовериться, что вы согласны с
                текущими изменениями.
                <br />
                (8) Ссылки на внешние сайтыСайт содержит ссылки на внешние
                сайты. Мы не несем ответственности за политики
                конфиденциальности внешних сайтов, а также за содержимое этих
                сайтов.
                <br />
                (9) Контактная информация – Вы можете связаться с нами
                посредством электронной почты
                <br />
            </span>
        </div>
    );
}
