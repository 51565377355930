import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";
import { IMessage, createMessage, getMessage } from "./hooks";
import { useEffect, useState } from "react";

export const GPTChat = () => {
  const [messages, setMessages] = useState<Array<IMessage> | null>(null);
  const [currentMessage, setCurrentMessage] = useState<string>("");
  const [next, setNext] = useState<string | null>("messages/list/");
  const [sendMessage, setSendMessage] = useState<boolean>(false);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if (messages === null) {
        const data = await getMessage(next);
        setCurrentMessage("");
        setNext(data.next == null ? "" : data.next);
        setMessages(data.results == null ? [] : [...data.results].reverse());
      }
    })();

    const intervalId = setInterval(async () => {
      if (sendMessage) {
        await handleMessage("messages/list/");
      }
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [messages]);

  const handleSendMessage = async () => {
    await createMessage(currentMessage);

    await handleMessage("messages/list/");
    setSendMessage(true);
  };

  const handleMessage = async (url?: string) => {
    if (next == null && url === undefined) return;

    const data = await getMessage(url ? url : next);
    setCurrentMessage("");
    setNext(next == null ? null : data.next == null ? null : data.next);
    if (messages !== null && data.results !== null) {
      const uniqueResults = data.results.filter((result: any) => {
        return !messages.some((message) => message.id === result.id);
      });

      if (uniqueResults.length > 0) {
        setSendMessage(false);
      }

      setMessages((prevMessages) =>
        prevMessages != null
          ? [...prevMessages, ...uniqueResults]
          : uniqueResults
      );
    } else {
      //   setMessages(data.results == null ? [] : data.results);
    }
  };

  const handleTypingIndicator = () => {
    return sendMessage ? (
      <TypingIndicator content="ии косметолог печатает" />
    ) : (
      false
    );
  };

  const fetchMore = async () => {
    if (loadingMore === true) {
      return;
    }

    setLoadingMore(true);

    if (next !== "") {
      handleMessage();
    }

    setLoadingMore(false);
  };

  return (
    <MainContainer
      style={{ borderRadius: "20px", height: "357px", background: "white" }}
      className="my-5"
    >
      <ChatContainer>
        <MessageList
          //   onYReachStart={fetchMore}
          typingIndicator={handleTypingIndicator()}
          loadingMore={loadingMore}
        >
          {messages != null &&
            messages.length > 0 &&
            messages.map((item) => (
              <Message
                key={item.id}
                model={{
                  message: item.text,
                  sentTime: item.timestamp,
                  direction: item.is_user ? "outgoing" : "incoming",
                  position: "single",
                }}
              />
            ))}
        </MessageList>
        <MessageInput
          onSend={handleSendMessage}
          onChange={(e: string) => setCurrentMessage(e)}
          placeholder="Напишите сообщение..."
          attachButton={false}
        />
      </ChatContainer>
    </MainContainer>
  );
};
