import { IHistory } from "../../../pages/profile/views/HistoryPurchase";
import { convertDateTime } from "../../../utils/date/convertFromIso";

export const HistoryItem = ({ id, status, created }: IHistory) => {
  return (
    <div className="flex justify-between" style={{ color: "white" }}>
      <span>{id}</span>
      <span>{status}</span>
      <span>{convertDateTime(created)}</span>
    </div>
  );
};
