import { useEffect, useRef, useState } from "react";
import { getRandomNumber } from "../../utils/randomIndex";
import { user } from "../..";
import { observer } from "mobx-react-lite";
import { GetChances, SendGift } from "./hooks";

export const FortunaItem = observer(() => {
  const ref = useRef<null | HTMLDivElement>(null);

  const [isSpinning, setIsSpinning] = useState<boolean>(true);
  const [chosenSegment, setChosenSegment] = useState<Array<IData> | null>(null);

  useEffect(() => {
    (async () => {
      if (chosenSegment === null) {
        setChosenSegment(await GetChances());
      }
    })();
  });

  useEffect(() => {
    user.tickets > 0 ? setIsSpinning(false) : setIsSpinning(true);
  }, []);

  const letsSpin = async () => {
    if (isSpinning === true) return;
    if (chosenSegment === null) return;

    await user.changeTickets(-1);

    setIsSpinning(true);

    const probabilities = chosenSegment.map((data, index) => data.value);
    const { deg, chosen } = getRandomNumber(probabilities);

    if (ref.current) {
      ref.current.style.transform = `rotate(${deg}deg)`;
    }

    await SendGift(chosenSegment[chosen].name);

    setTimeout(() => {
      setIsSpinning(false);
    }, 4000);
  };

  return (
    <div
      className="flex justify-center"
      style={{
        position: "relative",
        width: "1400px",
        height: "600px",
      }}
    >
      <div id="main" className="WheelMain">
        <div
          ref={ref}
          className={`wheel ${isSpinning ? "pulsating-effect " : ""}`}
        >
          <div>
            {chosenSegment &&
              chosenSegment.map((data: IData, index) => (
                <span className={`span${index + 1} span`}>
                  <p>{data.name}</p>
                </span>
              ))}
          </div>
        </div>

        <button
          className="spin"
          onClick={letsSpin}
          disabled={isSpinning || chosenSegment === null}
        >
          SPIN
        </button>
      </div>
    </div>
  );
});

interface IData {
  id: number;
  name: string;
  value: number;
}
