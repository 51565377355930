import { FormControl, OutlinedInput, useMediaQuery } from "@mui/material";

interface IProps {
  email: string;
  handleEmail: (text: string) => void;
}

export const LoginInputFields = ({ email, handleEmail }: IProps) => {
  const isMobile = useMediaQuery("(max-width:767px)");
  return (
    <>
      <FormControl
        style={{
          marginBottom: isMobile ? "20px" : "40px",
        }}
        fullWidth={true}
      >
        <OutlinedInput
          notched={false}
          placeholder="Email"
          type="email"
          onChange={(e: any) => handleEmail(e.target.value)}
          value={email}
          className="AuthInput"
        />
      </FormControl>
    </>
  );
};
