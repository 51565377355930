import { Button, CircularProgress } from "@mui/material";
import { useState } from "react";
import { FilePond } from "react-filepond";
import { SendPhoto } from "./hooks";
import { CVResult } from "./CVResults";

export const CVUploadPhoto = () => {
  const [file, setFile] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<Record<string, any> | null>(null);

  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const handleClick = async () => {
    setLoading(true);
    const form = new FormData();
    form.append("image", file[0].file);
    setData(await SendPhoto(form));
    setFile([]);
    setLoading(false);
    setOpenDialog(true);
  };

  const changeDialog = () => {
    setOpenDialog(!openDialog);
  };

  const UpdateData = (key: string, value: any) => {
    console.log({ ...data, [key]: value });
    setData({ ...data, [key]: value });
  };

  return (
    <>
      <span className="PurchaseSubtitle flex justify-center my-3"></span>
      <FilePond
        files={file}
        onupdatefiles={setFile}
        allowImagePreview={false}
        allowReorder={true}
        maxFiles={1}
        name="files"
        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
      />
      <div
        className="mt-28 flex  flex-col justify-around "
        style={{ color: "black" }}
      >
        <Button
          onClick={handleClick}
          disabled={file.length === 0 || loading}
          color="inherit"
          variant="contained"
          sx={{ borderRadius: "20px" }}
        >
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "Загрузить фото"
          )}
        </Button>
      </div>
      <CVResult
        changeDialog={changeDialog}
        openDialog={openDialog}
        data={data}
        UpdateData={UpdateData}
      />
    </>
  );
};
