import { useState } from "react";
import { user } from "../../..";
import { AuthView } from "../../../components/auth/Auth";
import { IAuth } from "../AuthRoot";

export default function RegistrationPage({
  disabled,
  handleDisabled,
  handlePass,
  handleEmail,
  email,
  password,
  handleShow,
}: IAuth) {
  const [name, setName] = useState<string>("");

  const handleName = (name: string) => setName(name);

  const handleButton = async () => {
    handleDisabled(true);
    if (!(email.length && password.length && name.length)) {
      handleShow("Поля не могут быть пустыми");
      return;
    } else if (!(email.length >= 8 || password.length >= 8)) {
      handleShow("Логин или пароль должны состоять не менее чем из 8 символов");
      return;
    }

    await user.registration(email, password, name);

    if (user.errorMessage.length) {
      handleShow(user.errorMessage);
    }

    handleDisabled(false);
  };

  return (
    <AuthView
      valName={name}
      handleName={handleName}
      name="Регистрация"
      handleButton={handleButton}
      disabled={disabled}
      password={password}
      email={email}
      handlePass={handlePass}
      handleEmail={handleEmail}
    />
  );
}
