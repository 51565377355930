import { ReactNode } from "react";

interface IProps {
  children: ReactNode;
}

export const BGBox = ({ children }: IProps) => {
  return (
    <div
      className="px-3 p-2 my-2"
      style={{
        background: "#629B85",
        color: "white",
        borderRadius: "10px",
      }}
    >
      {children}
    </div>
  );
};
