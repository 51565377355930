import { IAuth } from "../AuthRoot";
import { ModersView, RecoveryView } from "../../../components/auth/Auth";
import { user } from "../../..";
import { useNavigate } from "react-router-dom";

export default function ModeratorPage({
    handleShow,
    handleDisabled,
    disabled,
    handleEmail,
    handlePass,
    email,
    password,
}: IAuth) {
    const navigate = useNavigate();

    const handleButton = async () => {
        handleDisabled(true);
        if (!(password.length)) {
            handleShow("Пароль не может быть пустым");
            return;
        } else if (!(email.length >= 8 || password.length >= 8)) {
            handleShow("Пароль должен состоять не менее чем из 8 символов");
            return;
        }

        await user.login('temp63154@gmail.com', password);

        if (user.isAuth) {
            navigate("/profile");
        } else if (user.errorMessage.length) {
            handleShow(user.errorMessage);
        }

        handleDisabled(false);
    };

    return (
        <>
            <ModersView
                disabled={disabled}
                handlePass={handlePass}
                handleBut={handleButton}
                password={password}
            />
        </>
    );
}