import EmailActivatePage from "../components/auth/activate";
import AuthPage from "../pages/auth/AuthRoot";
import LoginPage from "../pages/auth/views/Login";
import RegistrationPage from "../pages/auth/views/Registration";
import FortunaRoot from "../pages/fortuna";
import PolicyPage from "../pages/policy/PolicyRoot";
import ProfileRootNav from "../pages/profile/ProfileRoot";
import { ProfilePage } from "../pages/profile/views/ProfilePage";

import TaskRoot from "../pages/tasks/TasksRoot";
import WheelPage from "../pages/wheel/WheelPage";
import {
  ACCOUNT,
  ACCOUNTBASE,
  ACTIVATE,
  AUTH,
  CV,
  CVBASE,
  CVPROCESS,

  FEEDBACK,
  FORTUNA,
  GPT,
  GPTBASE,
  LOGIN,
  MODERATOR,
  PASSRESET,
  POLICY,
  PROFILE,
  PROFILEROOT,
  PURCHASERESULT,
  PURCHASEROOT,
  PURCHASES,
  RECOVERY,
  REGISTRATION,
  TASK,
  TASKS,
  WHEEL,
} from "./consts";
import { TaskListPage } from "../pages/tasks/views/TaskList";
import FeedBackRoot from "../pages/tasks/views/FeedBackRoot";
import HistoryPurchase from "../pages/profile/views/HistoryPurchase";
import CVRoot from "../pages/cv/cvroot";
import { CVView } from "../pages/cv/views/cvView";
import { CVStart } from "../pages/cv/views/cvStart";
import { AccountView } from "../pages/account/views/AccountView";
import { GPTView } from "../pages/gpt/views/GPTView";
import GPTRoot from "../pages/gpt";
import { PurchasePage } from "../pages/purchase/views/PurchasePage";
import { PurchaseRoot } from "../pages/purchase/PurchaseRoot";
import { PResultPage } from "../pages/purchase/views/ResultPage";
import RecoveryPage from "../pages/auth/views/Recovery";
import PassResetPage from "../pages/auth/views/PassReset";
import { ModersView } from "../components/auth/Auth";
import ModeratorPage from "../pages/auth/views/Moderators";

export const MainURL = [
  {
    Component: AuthPage,
    path: AUTH,
  },
  {
    Component: ProfileRootNav,
    path: PROFILEROOT,
  },
  {
    Component: PolicyPage,
    path: POLICY,
  },
  {
    Component: WheelPage,
    path: WHEEL,
  },

];

export const AuthURL = [
  {
    Component: LoginPage,
    path: LOGIN,
  },
  {
    Component: RegistrationPage,
    path: REGISTRATION,
  },
  {
    Component: EmailActivatePage,
    path: ACTIVATE,
  },
  {
    Component: RecoveryPage,
    path: RECOVERY,
  },
  {
    Component: PassResetPage,
    path: PASSRESET,
  },
  {
    Component: ModeratorPage,
    path: MODERATOR,
  }
];

export const ProfileURL = [
  {
    Component: ProfilePage,
    path: PROFILE,
  },
  {
    Component: PurchaseRoot,
    path: PURCHASEROOT,
  },
  {
    Component: TaskRoot,
    path: TASKS,
  },
  {
    Component: FortunaRoot,
    path: FORTUNA,
  },
  {
    Component: HistoryPurchase,
    path: PURCHASES,
  },
  {
    Component: CVRoot,
    path: CV,
  },
  {
    Component: HistoryPurchase,
    path: ACCOUNT,
  },
  {
    Component: GPTRoot,
    path: GPT,
  }

];

export const PurchaseURL = [
  {
    Component: PurchasePage,
    path: "",
  },
  {
    Component: PResultPage,
    path: PURCHASERESULT,
  },
]

export const TaskURL = [
  {
    Component: FeedBackRoot,
    path: FEEDBACK,
  },
  {
    Component: TaskListPage,
    path: TASK
  }
]

export const CVURL = [
  {
    Component: CVStart,
    path: CVBASE,
  },
  {
    Component: CVView,
    path: CVPROCESS,
  }
]


export const AccountURL = [
  {
    Component: AccountView,
    path: ACCOUNTBASE,
  }
]

export const GPTURL = [
  {
    Component: GPTView,
    path: GPTBASE,
  }
]