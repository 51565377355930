import { Button } from "@mui/material";

import { useState } from "react";
import { FilePond } from "react-filepond";

import { PRSelectCatigory } from "./SelectCategory";

import { sendPurchase } from "./hooks";

import { user } from "../..";
import { PURCHASERESULT } from "../../utils/consts";
import { useNavigate } from "react-router-dom";

export const PurchaseInputs = () => {
  const navigate = useNavigate();

  const [file1, setFile1] = useState<any>([]);
  const [file2, setFile2] = useState<any>([]);
  const [selectedProduct, setSelectedProduct] = useState<number | null>(null);
  const [notification, setNotification] = useState<string>("");

  const handleSelectedProduct = (id: number, alert: string) => {
    setSelectedProduct(id);
    setNotification(alert);
  };

  const handleClick = async () => {
    const form = new FormData();

    selectedProduct && form.append("category", selectedProduct.toString());

    form.append("file1", file1[0].file);
    form.append("file2", file2[0].file);

    const data = await sendPurchase(form);

    if (data) {
      if (notification.length > 0) {
      }
      user.setPurschase(data, notification);

      navigate(PURCHASERESULT);
      setFile1([]);
      setFile2([]);
    }
  };

  return (
    <>
      <div className="flex flex-wrap justify-between">
        <div className=" w-96">
          <FilePond
            files={file1}
            onupdatefiles={setFile1}
            allowImagePreview={false}
            name="files"
            labelIdle="Чек о покупке товара"
          />
        </div>
        <div className="w-96">
          <FilePond
            files={file2}
            onupdatefiles={setFile2}
            allowImagePreview={false}
            name="files"
            labelIdle="Фотография товара"
          />
        </div>
      </div>
      <PRSelectCatigory
        handleSelectedProduct={handleSelectedProduct}
        selectedProduct={selectedProduct}
      />
      <div className="mt-28 flex justify-center" style={{ color: "black" }}>
        <Button
          onClick={handleClick}
          disabled={
            file1.length === 0 || selectedProduct == null || file2.length === 0
          }
          color="inherit"
          variant="contained"
          sx={{ borderRadius: "20px" }}
        >
          Зарегистрировать покупку
        </Button>
      </div>
    </>
  );
};
