import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Button, Chip } from "@mui/material";
import { BGBox } from "../../../components/general/box";
import { user } from "../../..";
import { observer } from "mobx-react-lite";
import { LotteryService } from "../../profile/views/ProfilePage";
import { PROFILEREDIRECT } from "../../../utils/consts";

export const PResultPage = observer(() => {
  return (
    <div className="flex justify-center" style={{ color: "white" }}>
      <div className="flex-col ">
        <div style={{ fontSize: "115px" }} className="my-3 flex justify-center">
          <CheckCircleOutlineIcon fontSize="inherit" />
        </div>
        <div style={{ fontSize: "30px" }} className="my-5">
          Покупка успешно зарегистрирована
        </div>
        <div className="flex justify-between my-3">
          <span>Ваш уникальный номер</span>
          <Chip label={user.pID} color="info" />
        </div>
        <BGBox>
          <div className="flex justify-center flex-col"></div>
          <span>До следующего крупного розыгрыша осталось</span>
          <div>
            <LotteryService />
          </div>
        </BGBox>
        <div className="flex justify-center mt-7">
          <a href={user.link} target="_blank" rel="noreferrer">
            <Button variant="outlined"> Пройдите опрос</Button>
          </a>
        </div>
        <div className="mt-4 flex justify-center">
          <Button variant="outlined" href={PROFILEREDIRECT}>
            На главную
          </Button>
        </div>
      </div>
    </div>
  );
});
