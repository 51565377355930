import { GPTChat } from "../../../components/gpt/chatElem";
import { GPTQuestions } from "../../../components/gpt/questions";

export const GPTView = () => {
  return (
    <>
      <span className="my-10 flex justify-center ProfileTitle ">
        ИИ-Косметолог
      </span>
      <div
        style={{ borderRadius: "10px", background: "#629B85", height: "64px" }}
        className="flex justify-center py-3"
      >
        Инструкция: Что бы использовать бота вам нужно написать первое сообщение
      </div>
      <GPTChat />
      {/* <GPTQuestions /> */}
    </>
  );
};
