import React from "react";
import "./App.css";
import { Navigate, Route, Routes } from "react-router-dom";
import "filepond/dist/filepond.min.css";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { registerPlugin } from "react-filepond";
import { MainURL } from "./utils/path";
import { Footer } from "./widgets/footer";

function App() {
  registerPlugin(FilePondPluginImagePreview);

  const renderRoutes = () => {
    return MainURL.map(({ path, Component }) => (
      <Route key={path} path={path} element={<Component />} />
    ));
  };

  // const isDesktop = useMediaQuery({ minWidth: 1700 });
  // const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1700 });
  // const isMobile = useMediaQuery("(max-width:767px)");

  return (
    <div className="flex flex-col min-h-screen">
      <div style={{ flex: 1 }}>
        <Routes>
          {renderRoutes()}
          <Route path="/error" element={<></>} />
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
      </div>

      <Footer />
    </div>
  );
}

export default App;
