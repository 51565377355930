import { Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";
import { AuthFormNotification } from "../../components/auth/compenents/NotificationItem";
import { AuthURL } from "../../utils/path";
import { SemilyNav } from "../../widgets/nav";

export default function AuthPage() {
  const [show, setShow] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const handlePass = (text: string) => {
    setPassword(text);
  };

  const handleEmail = (text: string) => {
    setEmail(text);
  };

  const handleShow = (text: string) => {
    setShow(text);
  };

  const handleDisabled = (flag: boolean) => {
    setDisabled(flag);
  };

  useEffect(() => {
    if (show?.length > 0) {
      const intervalId = setInterval(() => {
        setShow("");
        clearInterval(intervalId);
      }, 7000);
    }
  }, [show]);

  const renderRoutes = () => {
    return AuthURL.map(({ path, Component }) => (
      <Route
        key={path}
        path={path}
        element={
          <Component
            email={email}
            password={password}
            handleEmail={handleEmail}
            handlePass={handlePass}
            handleShow={handleShow}
            disabled={disabled}
            handleDisabled={handleDisabled}
          />
        }
      />
    ));
  };

  return (
    <>
      <SemilyNav isAuth={false} />
      <div className="flex flex-col justify-center w-80 mx-auto">
        {show.length > 0 && <AuthFormNotification text={show} />}
        <Routes>{renderRoutes()}</Routes>
      </div>
    </>
  );
}

export interface IAuth {
  handleShow: (text: string) => void;
  disabled: boolean;
  handleDisabled: (flag: boolean) => void;
  handlePass: (text: string) => void;
  handleEmail: (text: string) => void;
  email: string;
  password: string;
}
