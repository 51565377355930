import { Route, Routes } from "react-router-dom";
import { TaskURL } from "../../utils/path";

export default function TaskRoot() {
    const renderRoutes = () => {
        return TaskURL.map(({ path, Component }) => (
            <Route key={path} path={path} element={<Component />} />
        ));
    };

    return (
        <div className="flex justify-center flex-col min-h-screen">
            <Routes>{renderRoutes()}</Routes>
        </div>
    );
}
