import axios from "axios"
import { $api } from "../../utils/api/api"

export const SendPhoto = async (form: FormData) => {
    try {
        const resp = await axios.post(`${process.env.REACT_APP_CV_URL}process-image`, form)
        return resp.data
    }catch(error){

    }
}   

export const saveResultsUserView = async (form: FormData) => {
    try {
        await $api.put('account/view/save/', form)
    }catch (error){}
}
