import { $api } from "../../utils/api/api";

export const getListTasks = async () => {
  try {
    const resp = await $api.get("tasks/list/");
    console.log(resp.data);
    return resp.data;
  } catch (error) {
    return null;
  }
};

export const updateState = async (data: string) => {
  try {
    const resp = await $api.put("tasks/update/", { name: data });
  } catch (error) {}
};
