//main root

export const AUTH = "/auth/*";

export const PROFILEROOT = "profile/*";
export const POLICY = "/policy/";
export const WHEEL = "/";
export const PASSRESET = '/password-reset/:uid/:token'

//prfofile root
export const PROFILEREDIRECT = "/profile/";
export const CVREDIRECT = "/profile/cv/";

export const PROFILE = "";
export const PURCHASEROOT = "purchase/*";

export const TASKS = "tasks/*";
export const FORTUNA = "fortuna/";
export const PURCHASES = "purchases/"
export const CV = 'cv/*'
export const ACCOUNT = 'account/*'
export const GPT = 'gpt/*'


// Auth root
export const LOGIN = "";
export const REGISTRATION = "/reg";
export const ACTIVATE = "/activate/:uid/:token";
export const RECOVERY = "/recover";
export const MODERATOR = "/moders";


//ROOT
export const AUTHROOT = "/auth";
export const REGISTRATIONROOT = "/reg";


export const TASKSROOT = 'tasks/';
// Task root
export const FEEDBACK = "feedback/:name";
export const TASK = "";



// CV root
export const CVBASE = "";
export const CVPROCESS = "process/";


// Account root 
export const ACCOUNTREDIRECT = 'account/'

export const ACCOUNTBASE = ""


// GPT root
export const GPTREDIRECT = "gpt/"

export const GPTBASE = ''



// purchase root
export const PURCHASE = "purchase/";
export const PURCHASERESULT = 'result/';