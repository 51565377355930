import { user } from "../../..";
import { observer } from "mobx-react-lite";

export const ProfileCreditianals = observer(() => {
  return (
    <>
      <span style={{ fontSize: "32px", fontWeight: "700" }}>
        Добро пожаловать, <br />
        {user.name}
      </span>
    </>
  );
});
