import { useState } from "react";
import { Wheel } from "../../components/wheel/Wheel";
import { AlertContainer } from "../../components/wheel/Alert";
import { SemilyNav } from "../../widgets/nav";
import { FireConfetti } from "../../components/wheel/confetti";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { BGBox } from "../../components/general/box";

export default function WheelPage() {
  const [open, setOpen] = useState(false);

  const handleOpen = (flag: boolean) => {
    setOpen(flag);
  };

  return (
    <>
      <SemilyNav isAuth={false} />
      <AlertContainer open={open} handleOpen={handleOpen} />
      <FireConfetti open={open} />
      <FireConfetti open={open} />
      <div
        className="flex flex-col justify-center w-auto mx-auto"
        style={{ color: "white", overflow: "hidden" }}
      >
        <div className="flex justify-center flex-col my-12">
          <span className="WheelTitle1 snake">Добро пожаловать в Semily</span>
          <div className="flex justify-center">
            <BGBox>
              Крутите колесо и получите свой первый
              <br /> приз! Для его активации вам
              <br />
              нужно будет зарегистрироваться
            </BGBox>
          </div>
          <div className="flex justify-center">
            <KeyboardArrowDownIcon />
          </div>
        </div>
        <div className="flex justify-center ">
          <Wheel handleOpen={handleOpen} />
        </div>
      </div>
    </>
  );
}
