export const getRandomNumber = (probabilities: Array<number>) => {
  const segments: Array<number> = [];

  probabilities.forEach((probability, index) => {
    for (let i = 0; i < probability; i++) {
      segments.push(index);
    }
  });

  const randomIndex = Math.floor(Math.random() * segments.length);
  const chosen = segments[randomIndex];

  const degreesPerSegment = 45;

  const randomRounds = 3 + Math.floor(Math.random() * 3);
  const extraDegrees = randomRounds * 360;

  // Определите минимальные и максимальные градусы для сегмента
  const minDeg = chosen * degreesPerSegment;
  const maxDeg = minDeg + 12;

  // Выберите случайное значение между minDeg и maxDeg
  const segmentDegree = Math.random() * (maxDeg - minDeg) + minDeg;
  const deg = segmentDegree + extraDegrees;

  return { deg, chosen };
};
