import { PurchaseInputs } from "../../../components/purchase/InputFields";
import { InsructionInfo } from "../../../components/purchase/instruction";

export const PurchasePage = () => {
  return (
    <div style={{ color: "white" }} className="sm:px-5">
      <span className="PurchaseTitle flex justify-center my-5">
        Регистрация покупки
      </span>
      <InsructionInfo />
      <PurchaseInputs />
    </div>
  );
};
