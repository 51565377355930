import { Button } from "@mui/material";
import { FilePond } from "react-filepond";
import { useState } from "react";
import { sendFeedback } from "./hooks";

interface IProps {
  name: string;
}

export const FeedBack = ({ name }: IProps) => {
  const [files, setFiles] = useState<any>([]);

  const handleClick = async () => {
    const form = new FormData();
    form.append("name", name);

    form.append("file1", files[0].file);
    if (files.length > 1) {
      form.append("file2", files[1].file);
    }

    await sendFeedback(form);
    setFiles([]);
  };

  return (
    <div style={{ color: "white" }} className="sm:px-5">
      <span className="PurchaseTitle flex justify-center my-5">
        Загрузите свой отзыв
      </span>
      <span className="PurchaseSubtitle flex justify-center my-3">{name}</span>
      <FilePond
        files={files}
        onupdatefiles={setFiles}
        allowMultiple={true}
        allowImagePreview={false}
        allowReorder={true}
        maxFiles={2}
        name="files" /* sets the file input name, it's filepond by default */
        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
      />
      <div className="mt-28 flex justify-center" style={{ color: "black" }}>
        <Button
          onClick={handleClick}
          disabled={files.length === 0}
          color="inherit"
          variant="contained"
          sx={{ borderRadius: "20px" }}
        >
          Отправить отзыв
        </Button>
      </div>
    </div>
  );
};
