import { Box, Button } from "@mui/material";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import { updateState } from "../hooks";

interface IProps {
    name: string;
    data: string;
    id: string;
    handleMod: () => void;
}

export const TaskItem = ({ id, name, data, handleMod }: IProps) => {
    const handleTask = async () => {
        if (items.includes(id)) return;

        await updateState(id);
        handleMod();
    };

    return (
        <Box
            sx={{
                background: "#BCEBDC",
                padding: "10px 15px",
                borderRadius: "15px",
                marginTop: "5px",
            }}
        >
            <div className="flex items-center justify-between">
                <span>{name}</span>

                <div className="flex">
                    <div className="me-3 flex justify-center align-middle">
                        1
                        <ConfirmationNumberIcon />
                    </div>
                    <a href={data} target="_blank" rel="noreferrer">
                        <Button
                            color="success"
                            variant="contained"
                            onClick={handleTask}
                        >
                            К заданию
                        </Button>
                    </a>
                </div>
            </div>
        </Box>
    );
};

const items = ["wb", "ir", "ozon", "otzovik"];
