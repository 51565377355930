import { useRef, useState } from "react";
import CachedIcon from "@mui/icons-material/Cached";
import { IconButton } from "@mui/material";

export const Wheel = ({ handleOpen }: IProps) => {
  const ref = useRef<null | HTMLDivElement>(null);
  const [isSpinning, setIsSpinning] = useState<boolean>(false);

  const letsSpin = () => {
    setIsSpinning(true);

    setIsSpinning(true);

    const extraDegrees = 5 * 360;

    const deg = extraDegrees + 100;
    if (ref.current) {
      ref.current.style.transform = "rotate(" + deg + "deg)";
    }

    setTimeout(() => {
      handleOpen(true);
      setIsSpinning(false);
    }, 2000);
  };

  return (
    <div
      className="flex justify-center"
      style={{ position: "relative", minHeight: "600px" }}
    >
      <div id="main" className="WheelMain">
        <div
          ref={ref}
          className={`wheel ${isSpinning ? "pulsating-effect " : ""}`}
        >
          <div>
            <span className="span1 span">
              <p>😊</p>
            </span>
            <span className="span span2 ">
              <p>😝</p>
            </span>
            <span className="span3 span">
              <p>😍</p>
            </span>
            <span className="span4 span">
              <p>😎</p>
            </span>
            <span className="span5 span">
              <p>🍩</p>
            </span>
            <span className="span6 span">
              <p>🍭</p>
            </span>
            <span className="span7 span">
              <p>🍰</p>
            </span>
            <span className="span8 span">
              <p>🍬</p>
            </span>
          </div>
        </div>
        <div
          className="spin flex justify-center"
          style={{ background: "white" }}
        >
          <IconButton onClick={letsSpin} disabled={isSpinning}>
            <CachedIcon color="inherit" fontSize="large" />
          </IconButton>
        </div>
        {/* <button></button> */}
      </div>
    </div>
  );
};

interface IProps {
  handleOpen: (flag: boolean) => void;
}
