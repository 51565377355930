export const InsructionInfo = () => {
  return (
    <div
      className=" flex justify-center my-4
    z"
    >
      <div
        className="px-2 py-5"
        style={{ background: "#629B85", width: "320px", borderRadius: "10px" }}
      >
        Инструкция: Что бы добавить регистрацию покупки выберете ваш товар из
        выпадающего списка.
        <br /> Затем загрузите две фотографии. Чек и Товар с видимым артикулом
        <br />
        <br />
        После этого вы получите уникальный номер
      </div>
    </div>
  );
};
