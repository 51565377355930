export const convertDateTime = (isoDateTime: string) => {
    const date = new Date(isoDateTime);
    const options: Intl.DateTimeFormatOptions = {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
    };

    return date.toLocaleDateString("ru-RU", options);
};


export const convertFromSeconds = (sec: string): string => {
    const seconds = Number(sec)

    const date = new Date(0);
    date.setSeconds(seconds);

    const options: Intl.DateTimeFormatOptions = {
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false, // Use 24 hour format
    };


    return date.toLocaleTimeString("ru-RU", options);
};