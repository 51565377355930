import { Alert } from "@mui/material";

interface IProps {
  text: string;
}

export const AuthFormNotification = ({ text }: IProps) => (
  <div
    style={{
      position: "absolute",
      left: "50%",
      top: "30%",
      transform: "translate(-50%, -50%)",
      zIndex: 10000,
    }}
  >
    <Alert variant="filled" severity="info">
      {text}
    </Alert>
  </div>
);
