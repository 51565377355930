import { Route, Routes } from "react-router-dom";
import { ProfileURL } from "../../utils/path";
import { user } from "../..";
import { when } from "mobx";
import { SemilyNav } from "../../widgets/nav";

export default function ProfileRootNav() {
  when(
    () => !user.email.length,
    () => {
      user.getMe();
    }
  );

  const renderRoutes = () => {
    return ProfileURL.map(({ path, Component }) => (
      <Route key={path} path={path} element={<Component />} />
    ));
  };

  return (
    <>
      <SemilyNav isAuth={true} />
      <div
        className="flex flex-col justify-center  mx-auto"
        style={{ maxWidth: "1024px" }}
      >
        <Routes>{renderRoutes()}</Routes>
      </div>
    </>
  );
}
