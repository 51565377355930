import { Image } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

interface Iprops {
  image: string;
  href: string;
}

export const IconButtonComp = ({ href = "", image }: Iprops) => {
  const navigate = useNavigate();

  return (
    <div
      style={{ background: "#238567", borderRadius: "10px", cursor: "pointer" }}
      className="m-1 p-1"
      onClick={() => navigate(href)}
    >
      <img src={image} alt="" />
    </div>
  );
};
