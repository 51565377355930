import { useNavigate, useParams } from "react-router-dom";
import { PassResetView } from "../../../components/auth/Auth";
import { IAuth } from "../AuthRoot";
import { useState } from "react";
import { user } from "../../..";
import { AUTHROOT } from "../../../utils/consts";

export default function PassResetPage({
  handleShow,
  handleDisabled,
  disabled,
  handleEmail,
  handlePass,
  email,
  password,
}: IAuth) {
  const { uid, token } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    new_password: "",
    re_new_password: "",
  });

  const handleUpload = async () => {
    handleDisabled(true);

    if (
      formData.new_password.length &&
      formData.re_new_password.length &&
      formData.new_password === formData.re_new_password &&
      uid &&
      token
    ) {
      const flag = await user.resetPassword(formData, uid, token);
      if (flag) {
        handleShow("Успешно");
        navigate(AUTHROOT);
      } else handleShow("Ошибка повторите попытку");
    } else {
      handleShow("Пароли не совпадают");
    }
    handleDisabled(false);
  };

  return (
    <>
      {uid && token && (
        <PassResetView
          disabled={disabled}
          handleButton={handleUpload}
          formData={formData}
          setFormData={setFormData}
        />
      )}
    </>
  );
}
