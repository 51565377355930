import { Route, Routes } from "react-router-dom";
import { PurchaseURL } from "../../utils/path";

export const PurchaseRoot = () => {
  const renderRoutes = () => {
    return PurchaseURL.map(({ path, Component }) => (
      <Route key={path} path={path} element={<Component />} />
    ));
  };

  return <Routes>{renderRoutes()}</Routes>;
};
