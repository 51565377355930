import { IconButton, useMediaQuery } from "@mui/material";
import { POLICY } from "../utils/consts";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import TelegramIcon from "@mui/icons-material/Telegram";
import { CustomLink } from "../components/general/link";

export const Footer = () => {
  const isMobile = useMediaQuery("(max-width:767px)");

  return (
    <div>
      <div style={{ color: "white" }} className="flex justify-end my-1">
        <IconButton
          href="https://instagram.com/semily_official?igshid=YmMyMTA2M2Y="
          color="inherit"
          target="_blank"
        >
          <InstagramIcon />
        </IconButton>
        <IconButton
          href="https://t.me/+gAtqWj6M-_gwNjIy"
          color="inherit"
          target="_blank"
        >
          <TelegramIcon />
        </IconButton>
        <IconButton
          href="https://youtube.com/@semily1523"
          color="inherit"
          target="_blank"
        >
          <YouTubeIcon />
        </IconButton>
      </div>

      <div
        className="w-full py-3"
        style={{ background: "#629B85", color: "white" }}
      >
        <div className={`flex justify-between ${isMobile ? "px-8" : "px-16"}`}>
          <div className="flex flex-col">
            {paths.map((item) => (
              <CustomLink text={item} href={POLICY} />
            ))}
          </div>
          <div>
            Есть вопрос?
            <br /> Напишите нам в TG: <br />
            <a
              style={{ color: "blue" }}
              href="https://t.me/semily_family"
              target="_blank"
              rel="noreferrer"
            >
              @semily_family
            </a>
          </div>
        </div>
        <div
          className="flex justify-evenly mt-1"
          style={{ background: "#629B85" }}
        >
          Semily, 2023. Все права защищены.
        </div>
      </div>
    </div>
  );
};

const paths = [
  "Политика конфиденциальности",
  "Условия использования",
  "Контактная информация",
];
