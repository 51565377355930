import { Button } from "@mui/material";
import { ProfileButtons } from "../../../components/profile/components/profileButtons/buttons";
import { ProfileCreditianals } from "../../../components/profile/profile/Creditials";
import { useEffect, useState } from "react";
import { FecthLottery } from "../../../components/profile/hooks";
import { convertFromSeconds } from "../../../utils/date/convertFromIso";
import { BGBox } from "../../../components/general/box";

export const ProfilePage = () => {
  return (
    <>
      <div style={{ color: "white" }} className="text-center mx-auto">
        <div className="my-5"></div>
        <ProfileCreditianals />
        <LotteryService />

        <ProfileButtons />

        <a
          target="_blank"
          href="https://hipolink.me/semily
"
          rel="noreferrer"
        >
          <Button sx={{ background: "#629B85" }} color="inherit" size="large">
            Наши товары
          </Button>
        </a>
      </div>
    </>
  );
};

export const LotteryService = () => {
  const [time, setTime] = useState<string | null>(null);

  useEffect(() => {
    if (time === null) {
      (async () => {
        setTime(await FecthLottery());
      })();
    }
  }, [time]);

  return (
    <>
      {time && (
        <BGBox>
          До ежемесячного розыгрыша: <br />
          <span className="font-bold">{convertFromSeconds(time)}</span>
        </BGBox>
      )}
    </>
  );
};
